import React, { Component } from 'react';

class QA extends Component {
  /**
  * A simple class that has a question and answer that it displays via accordion.
  */

  constructor(values) {
    // Required step: always call the parent class' constructor
    super();
    var value = values.value;
    this.state = {
      question: value['Q'],
      answer: value['A']
    }

  }

  createMarkup(str) {
    // We need this function so that we can wrap text to be used as innerHTML.
    return {__html: str};
  }

  render() {

    return (
      <details className="accordion">
          <summary className="accordion-header">
              <i className="icon icon-arrow-right mr-1"></i>
              <b dangerouslySetInnerHTML={this.createMarkup(this.state.question)}></b>
          </summary>
          <div className="accordion-body" dangerouslySetInnerHTML={this.createMarkup(this.state.answer)}>
          </div>
      </details>
    );
  }
}

export default QA;
