import React, { Component } from 'react';
import EnhancedDataTable from './dataset_leaderboard.js';
import EnhancedMetricsTable from './metrics_leaderboard.js';

/**
* Leaderboard that supports multiple iterations of a table.
*/
class Leaderboard extends Component {

  /**
  * Decide what table to display.
  */
  constructor() {
    super();
    this.state = {mode: 'dataset'};
  }

  /**
  * A function to pass to a table to modify what table to take.
  */
  mode_changer = (table_type) => {
      this.setState({mode: table_type});
  }
  /**
  * Render a table based on the state.
  */
  render() {

    return (
      <div id="leaderboard" className="bg-gray">
          <div className="container grid-lg section-header">
              <div className="columns">
                  <div className="column col-12">
                    <h1>Leaderboard</h1>
                    <h6>Click on a column to sort it. Up arrows denote sorting by ascending order, while down arrows denote descending order.</h6>
                    <br/>
                      {this.state.mode === 'dataset' ? <EnhancedDataTable modeChanger={this.mode_changer}/> : <EnhancedMetricsTable modeChanger={this.mode_changer} />}
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default Leaderboard;
