import React, { Component } from 'react';
import Task from './task.js';

class TaskBoard extends Component {
  /**
  * A section where links to the original dataset's websites and zip files are.
  */

  constructor() {
    // Required step: always call the parent class' constructor
    super();
    var taskboard = [];
    var tasks = {'Eraser': ['Eraser', 'https://github.com/jayded/eraserbenchmark', 'https://storage.googleapis.com/sfr-nazneen-website-files-research/data_v1.2.tar.gz'],
                 'BoolQ': ['BoolQ', 'https://github.com/google-research-datasets/boolean-questions', '/zipped/boolq.tar.gz'],
                 'MultiRC': ['MultiRC', 'https://github.com/CogComp/multirc', '/zipped/multirc.tar.gz'],
                 'E-SNLI': ['E-SNLI', 'https://github.com/OanaMariaCamburu/e-SNLI', '/zipped/esnli.tar.gz'],
                 'CoS-E': ['CoS-E', 'https://github.com/salesforce/cos-e', '/zipped/cose.tar.gz'],
                 'Fever': ['Fever', 'https://github.com/awslabs/fever', '/zipped/fever.tar.gz'],
                 'Evidence Inference': ['Evidence Inference', 'https://github.com/jayded/evidence-inference', '/zipped/evidence_inference.tar.gz'],
                 'Movies': ['Movies', 'http://www.cs.jhu.edu/~ozaidan/rationales/', '/zipped/movies.tar.gz'],
                 'SciFact': ['SciFact', 'https://scifact.apps.allenai.org/', 'https://storage.googleapis.com/sfr-nazneen-website-files-research/scifact.tar.gz']
                };

    var keys = ['Eraser', 'BoolQ', 'MultiRC', 'E-SNLI', 'CoS-E', 'Fever', 'Evidence Inference', 'Movies', 'SciFact'];

    for (var i = 0; i < keys.length; i++) {
      taskboard.push(<Task value={ tasks[keys[i]] } key = {i} />);
    }

    // Set the state directly. Use props if necessary.
    this.state = {
      taskboard: taskboard,
      keys: keys,
      tasks: tasks
    }

  }

  render() {

    return (
      <div id="tasks" className="container grid-lg section-header">
          <div className="columns">
              <div className="column col-12">
                  <h1>Tasks</h1>
                  <div className="filter">
                      <div className="filter-body columns">
                        {this.state.taskboard[0]}
                        {this.state.taskboard[1]}
                        {this.state.taskboard[2]}
                      </div>

                      <div className="filter-body columns">
                        {this.state.taskboard[3]}
                        {this.state.taskboard[4]}
                        {this.state.taskboard[5]}
                      </div>

                      <div className="filter-body columns">
                        {this.state.taskboard[6]}
                        {this.state.taskboard[7]}
                        {this.state.taskboard[8]}
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default TaskBoard;
