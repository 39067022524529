/**
* Is object A or B w.r.t the given comparator.
* @param A object with field == orderBy to compare.
* @param B object with field == orderBy to compare.
* @param orderBy is the field to compare with.
* @param order is asc or desc.
* @return 1 if a is smaller, 0 if equal, -1 if a is bigger.
*/
function descendingComparator(a, b, orderBy, order) {
  if (a[orderBy] === null && order === 'asc') {
    return -1;
  } else if (a[orderBy] === null && order === 'desc') {
    return 1;
  } else if (b[orderBy] === null && order === 'asc') {
    return 1;
  } else if (b[orderBy] === null && order === 'desc') {
    return -1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

/**
* Should we ascend or descend with respect to the ordering?
* @param order is either 'desc' or otherwise.
* @param orderBy what field to sort by.
* @return a function that will allow us to sort.
*/
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

/**
* Sort this array with respect to the comparator.
* @param array to be sorted.
* @param comparator is how we compare elements
* @return return a sorted array that uses original elements as tiebreaker.
*/
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
