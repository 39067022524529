import React, { Component } from 'react';

class HeadBoard extends Component {
  /**
  * The header with the links to items further down the page.
  */

  render() {

    return (
      <div className="div-headboard">
          <div className="container grid-lg">
              <div className="columns">
                  <div className="column col-12">
                      <header className="navbar navbar-padding">
                          <section className="navbar-section">
                              <a href="#tasks" className="btn btn-link">Tasks</a>
                              <a href="#leaderboard" className="btn btn-link">Leaderboard</a>
                          </section>
                          <section className="navbar-center">
                              <span className="super span-margin">Er</span>
                              <a href="#intro">
                                  <img alt="ERASER" src="eraser.svg" height="36"/>
                              </a>
                              <span className="super span-margin">ser</span>
                          </section>
                          <section className="navbar-section">
                              <a href="#FAQ" className="btn btn-link">FAQ</a>
                              <a href="#appendix" className="btn btn-link">Appendix</a>
                              <a href = "https://arxiv.org/abs/1911.03429" className = "btn btn-link">Paper</a>
                          </section>

                      </header>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default HeadBoard;
