import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import HelpIcon from '@material-ui/icons/Help';
import {stableSort, getComparator} from './utils.js';

function createData(system, description, average, boolq, multirc, esnli, cose, fever, evidenceinference, movies, scifact) {
  return {system, description, average, boolq, multirc, esnli, cose, fever, evidenceinference, movies, scifact};
}

function createDataWrapper(a) {
  return createData(a[0], a[1], a[2], a[3], a[4], a[5], a[6], a[7], a[8], a[9]);
}

/** Header information. */
const headCells = [
  { id: 'system', numeric: false, disablePadding: false, label: 'System'},
  { id: 'average', numeric: true, disablePadding: true, label: 'Avg.'},
  { id: 'boolq', numeric: true, disablePadding: true, label: 'BoolQ'},
  { id: 'multirc', numeric: true, disablePadding: true, label: 'Multi-RC'},
  { id: 'esnli', numeric: true, disablePadding: true, label: 'E-SNLI'},
  { id: 'cose', numeric: true, disablePadding: true, label: 'Cos-E' },
  { id: 'fever', numeric: true, disablePadding: true, label: 'Fever' },
  { id: 'evidenceinference', numeric: true, disablePadding: true, label: 'Evidence Inference' },
  { id: 'movies', numeric: true, disablePadding: true, label: 'Movies'},
  { id: 'scifact', numeric:true, disablePadding: true, label: 'Sci-Fact'},
];

/**
* Represents the head of the table (i.e. what type of information we display).
*/
function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property, all_names) => (event) => {
    var names = all_names;
    for (var i = 0; i < names.length; i++) {
      if (names[i] == 'system') {
        continue;
      }

      document.getElementById(names[i]).style.fontWeight = 'normal';

    }

    document.getElementById(property).style.fontWeight = 'bold';
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style = {{cursor: "pointer"}}
            id={headCell.id}
          >

          <div onClick={createSortHandler(headCell.id, headCells.map((c) => c.id))} style = {{marginRight: "10px"}}>
            {(() => {
                    switch (headCell.direction) {
                      case "asc":    return <ArrowUpwardIcon  fontSize="inherit" />;
                      case "desc":   return <ArrowDownwardIcon  fontSize="inherit"/>;
                      default:       return null;
                    }
            })()}
            {headCell.label}

            </div>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/**
* Specify data and what is required.
*/
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/**
* Styling for the toolbars.
*/
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

/**
* Represents the header ABOVE table entries.
*/
function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles(); // allow us to style accordingly
  const [dataset, setDataset] = React.useState('Performance');
  const [mode, setMode] = React.useState('metrics');
  const { tableReload, modeChanger } = props;

  /**
  * What dataset do they want to see?
  */
  const handleChange = (event) => {
    setDataset(event.target.value);
    tableReload(event.target.value);
  };

  /**
  * What dataset mode do they want to see?
  */
  const handleModeChange = (event) => {
    modeChanger(event.target.value);
  };

  return (
    <Toolbar>
    <InputLabel id="demo-simple-select-placeholder-label-label" >
      Metric:
    </InputLabel>

    <FormControl className={classes.formControl} style={{marginLeft: "2%", marginRight: "2%"}}>
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={dataset}
        onChange={handleChange}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value={"Performance"}><em>Performance</em>
          <ArrowUpwardIcon fontSize="inherit"/>
        </MenuItem>
        <MenuItem value={"IOU"}>IOU
          <ArrowUpwardIcon fontSize="inherit"/>
        </MenuItem>
        <MenuItem value={"Token F1"}>Token F1
          <ArrowUpwardIcon fontSize="inherit"/>
        </MenuItem>
        <MenuItem value={"AUPRC"}>AUPRC
          <ArrowUpwardIcon fontSize="inherit"/>
        </MenuItem>
        <MenuItem value={"Comprehensiveness AOPC"}>Comprehensiveness AOPC
          <ArrowUpwardIcon fontSize="inherit"/>
        </MenuItem>
        <MenuItem value={"Sufficiency AOPC"}>Sufficiency AOPC
          <ArrowDownwardIcon fontSize="inherit"/>
        </MenuItem>
        <MenuItem value={"Random Sufficiency AOPC"}>Random Sufficiency AOPC
        </MenuItem>
        <MenuItem value={"Random Comprehensiveness AOPC"}>Random Comprehensiveness AOPC
        </MenuItem>
      </Select>
    </FormControl>

    <InputLabel>
      View Mode:
    </InputLabel>

    <FormControl className={classes.formControl} style={{marginLeft: "2%"}}>
      <Select
        onChange={handleModeChange}
        value={mode}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="dataset"><em>Dataset</em></MenuItem>
        <MenuItem value="metrics">Metrics</MenuItem>
      </Select>
    </FormControl>
    </Toolbar>
  );
};

/**
* What is required for the dataset.
*/
EnhancedTableToolbar.propTypes = {
  tableReload: PropTypes.func.isRequired,
  modeChanger: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

/**
* Generate the table w.r.t the type requested.
*/
const get_table_data = (type, setData) => {
  var rows = null;
  console.log(type);
  switch (type) {
    case 'Performance':
      fetch("/performance").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'AUPRC':
      fetch("/auprc").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Accuracy':
      fetch("/accuracy").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Task F1':
      fetch("/f1").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Comprehensiveness':
      fetch("/comp").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Comprehensiveness AOPC':
      fetch("/compAOPC").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Sufficiency':
      fetch("/suff").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Sufficiency AOPC':
      fetch("/suffAOPC").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'IOU':
      fetch("/iou").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Span F1':
      fetch("/rationalePrf").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Token F1':
      fetch("/tokenPrf").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Random Sufficiency':
      fetch("/randSuff").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Random Sufficiency AOPC':
      fetch("/randSuffAOPC").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Random Comprehensiveness AOPC':
      fetch("/randCompAOPC").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;
    case 'Random Comprehensiveness':
      fetch("/randComp").then(res => res.json()).then(res => setData(res.map(createDataWrapper)));
      break;

    default:
      rows = [
               createData('Huge Eric', 'ah', 6, 305, 3.7, 67, 4.3, 10.0, 5.0, 1.2, 6.4, 3.6),
               createData('Enormous Eric', 'ah', 0, 452, 25.0, 51, 4.9, 11.5, 5.0, 1.2, 2.1, 3.6),
               createData('Boundless Eric', 'ah', 3, 10, 16.0, 24, 6.0, 12.6, 7.9, 1.2, 2.4, 3.6),
             ];
      setData(rows);
  }
}

export default function EnhancedMetricsTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = React.useState([]);
  const [metric, setMetric] = React.useState('Accuracy');
  const {modeChanger} = props;

  if (data.length === 0) {
    get_table_data('Accuracy', setData);
  }

  /**
  * Reload the table to have the data asked about.
  */
  const reloadTable = (new_metric) => {
    setMetric(new_metric);
    get_table_data(new_metric, setData);
  }

  /**
  * Sort the given column.
  */
  const handleRequestSort = (event, property) => {
    var isAsc = true; // Here is where we find out what to sort by.
    switch(metric) {
      case 'Sufficiency':
        isAsc = false;
        break;
      case 'Sufficiency AOPC':
        isAsc  = false;
        break;
      case 'Random Sufficiency':
        return;
      case 'Random Sufficiency AOPC':
        return;
      default:
        break;
    }

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
  * Changing of pages.
  */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
  * How many rows per page?
  */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar tableReload={reloadTable} modeChanger={modeChanger}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.system);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.system}
                    >
                      <TableCell component="th" id={labelId} style={{fontWeight: "bold"}}>
                        <div>
                        {row.system}
                        <Tooltip title={row.description} placement="right">
                          <HelpIcon style={{marginRight: "10px"}}  fontSize="inherit" className="small_svg_icon"/>
                        </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.average}</TableCell>
                      <TableCell align="right">{row.boolq}</TableCell>
                      <TableCell align="right">{row.multirc}</TableCell>
                      <TableCell align="right">{row.esnli}</TableCell>
                      <TableCell align="right">{row.cose}</TableCell>
                      <TableCell align="right">{row.fever}</TableCell>
                      <TableCell align="right">{row.evidenceinference}</TableCell>
                      <TableCell align="right">{row.movies}</TableCell>
                      <TableCell align="right">{row.scifact}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

/**
* What is required for the dataset.
*/
EnhancedMetricsTable.propTypes = {
  modeChanger: PropTypes.func.isRequired
};
