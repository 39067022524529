import React, { Component } from 'react';

class Icon extends Component {
  /**
  * This class represents an icon that is a link to another cite.
  * Takes in values to represent the link that the button goes to, as well
  * as some titles to go with that link.
  */

  constructor(values) {
    super();
    var value = values.values;
    this.state = {
      link: value['link'],
      title1: value['title1'],
      title2: value['title2']
    }
  }

  render() {

    return (
      <div className="column col-3 col-md-6 col-xs-12">
          <div className="tile tile-centered">
              <div className="tile-icon">
                  <div className="example-tile-icon">
                      <a href={this.state.link} target="_blank">
                          <button className="btn btn-primary btn-action btn-lg">
                              <i className="icon icon-link centered"></i>
                          </button>
                      </a>
                  </div>
              </div>
              <div className="tile-content">
                  <div className="tile-title">{this.state.title1}</div>
                  <small className="tile-subtitle text-gray"><a href={this.state.link}>{this.state.title2}</a></small>
              </div>
          </div>
        </div>
    );
  }
}

export default Icon;
