import React, { Component } from 'react';

class Task extends Component {
  /**
  * Contains a single website's links for download + information.
  */

  constructor(values) {
    // Required step: always call the parent class' constructor
    super();
    var value = values.value;
    this.state = {
      names: value[0],
      link: value[1],
      download: value[2]
    }
  }

  render() {
    return (
      <div className="column col-3 filter-item" style={{"padding": ".4rem"}}>
          <div className="card" style={{"boxShadow": "0 2px 4px 0 rgba(0,0,0,.2)"}}>
              <div className="card-header">
                  <div className="card-title text-bold">{this.state.names}</div>
                  <div className="card-subtitle text-gray"><a href ={this.state.link}>Website Link</a></div>
                  <div className="card-subtitle text-gray"><a href ={this.state.download}>Download</a></div>
              </div>
          </div>
      </div>


    );
  }
}

export default Task;
