import React, { Component } from 'react';
import Icon from './icon.js';

class Appendix extends Component {
  /**
  * The appendix section, where it makes use of an 'icon' class to store
  * links to various other pages of relevance.
  */

  render() {
    return (
      <div id="appendix" className="bg-gray">
        <div className="container grid-lg section-header">
          <div className="columns">
              <div className="column col-12">
                  <h1>Appendix</h1>
              </div>

              <Icon values={{title1: 'Github', title2: 'Link', link: 'https://github.com/jayded/eraserbenchmark'}}/>
              <Icon values={{title1: 'Eraser Paper', title2: 'Arxiv Link', link: 'https://arxiv.org/abs/1911.03429'}}/>
              <Icon values={{title1: 'Blog', title2: 'Link', link: 'https://blog.einstein.ai/eraser-a-benchmark-to-evaluate-rationalized-nlp-models/'}}/>

            </div>

          <br/> <br/>
          <small> This work was supported in part by the National Science Foundation (CAREER award 1750978). </small>
          <br/>
          <small> Special thanks to Melvin Gruesbeck for designing this website </small>
        </div>
      </div>
    );
  }
}

export default Appendix;
