import React, { Component } from 'react';

class Introduction extends Component {
  /**
  * The introduction to the dataset. Nothing really fancy going on here.
  */

  render() {

    return (
      <div id="intro" className="bg-gray" style={{"paddingTop": "76px"}}>
          <div className="container grid-lg">
              <div className="columns">
                  <div className="column col-12">
                      <div className="hero bg-gray">
                          <div className="hero-body">
                              <h1 className="super-default text-center" style={{"fontSize": "5rem"}}>Eraser</h1>
                              <p>The need for more interpretable models in NLP
                                  has become increasingly apparent in recent years.
                                  The Evaluating Rationales And Simple English
                                  Reasoning <span className="chip" style={{"backgroundColor": "#ceccff"}}> <img src="/eraser.svg"
                                          alt="eraser" className="avatar avatar-sm" style={{"backgroundColor": "#fff"}}/> ERASER </span>
                                  benchmark is intended
                                  to advance research in this area by providing a
                                  diverse set of NLP datasets that contain both
                                  document labels and snippets of text marked by
                                  annotators as supporting these.</p>

                              <p>Models
                                  that provide rationales supporting predictions
                                  can be evaluated using this benchmark using
                                  several metrics (<em>see below</em>) that aim to quantify
                                  different attributes of “interpretability”. We do
                                  not privilege any one of these, or provide a
                                  single number to quantify performance, because we
                                  argue that the appropriate metric to gauge the
                                  quality of rationales will depend on the task and
                                  use-case.</p>
                              <div className="p-centered text-center">
                                  <img src="logo_northeastern.svg" className="logo" alt="northeastern"/>
                                  <img src="logo-salesforce.svg"  className="logo" alt="salesforce"/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default Introduction;
