import React, { Component } from 'react';
import QA from './simple_faq_template';

class FAQ extends Component {
  /**
  * Compiles a list of FAQ using accordions. Uses a simple QA format to reduce
  * code length that has a simple, single-line, question, and simple, single-line,
  * answer.
  */

  constructor() {
    super();
    var text = String.raw`
    {
      "annotation_id": str, required
      # these classifications *must not* overlap
      "rationales": List[
          {
              "docid": str, required
              "hard_rationale_predictions": List[{
                  "start_token": int, inclusive, required
                  "end_token": int, exclusive, required
              }], optional,
              # token level classifications, a value must be provided per-token
              # in an ideal world, these correspond to the hard-decoding above.
              "soft_rationale_predictions": List[float], optional.
              # sentence level classifications, a value must be provided for every
              # sentence in each document, or not at all
              "soft_sentence_predictions": List[float], optional.
          }
      ],
      # the classification the model made for the overall classification task
      "classification": str, optional
      # A probability distribution output by the model. We require this to be normalized.
      "classification_scores": Dict[str, float], optional
      # The next two fields are measures for how faithful your model is (the
      # rationales it predicts are in some sense causal of the prediction), and
      # how sufficient they are. We approximate a measure for comprehensiveness by
      # asking that you remove the top k%% of tokens from your documents,
      # running your models again, and reporting the score distribution in the
      # "comprehensiveness_classification_scores" field.
      # We approximate a measure of sufficiency by asking exactly the converse
      # - that you provide model distributions on the removed k%% tokens.
      # 'k' is determined by human rationales, and is documented in our paper.
      # You should determine which of these tokens to remove based on some kind
      # of information about your model: gradient based, attention based, other
      # interpretability measures, etc.
      # scores per class having removed k%% of the data, where k is determined by human comprehensive rationales
      "comprehensiveness_classification_scores": Dict[str, float], optional
      # scores per class having access to only k%% of the data, where k is determined by human comprehensive rationales
      "sufficiency_classification_scores": Dict[str, float], optional
      # the number of tokens required to flip the prediction - see "Is Attention Interpretable" by Serrano and Smith.
      "tokens_to_flip": int, optional
      "thresholded_scores": List[{
          "threshold": float, required,
          "comprehensiveness_classification_scores": like "classification_scores"
          "sufficiency_classification_scores": like "classification_scores"
      }], optional. if present, then "classification" and "classification_scores" must be present
    }
    `;

    var metadata = String.raw`
      {
          "name": str, required
          "description": str, required
          "citation": str, recommended
      }
    `;

    this.state = {text: text, metadata: metadata};

  }

  render() {
    return (
      <div id="FAQ" className="container grid-lg section-header">
          <div className="columns">
              <div className="column col-12">
                  <h1>FAQ</h1>

                  <details className="accordion" open>
                      <summary className="accordion-header">
                          <i className="icon icon-arrow-right mr-1"></i>
                          <b>How do I use ERASER?</b>
                      </summary>
                      <div className="accordion-body">
                          <p><span className="label label-rounded label-secondary">Step 1</span> Download the data from our <a
                                  href="https://github.com/jayded/eraserbenchmark">Github
                                  page.</a></p>
                          <p><span className="label label-rounded label-secondary">Step 2</span> The evaluation script uses
                              files in the following JSONL format:</p>
                          <pre className="code" data-lang="JSONL">
                            <code>
                              {this.state.text}
                            </code>
                          </pre>
                          <p><span className="label label-rounded label-secondary">Step 3</span> In total, there should be 8
                              files: 1 for each of the datasets, plus an additional meta data file. Make sure that each prediction
                              JSONL is named according to the following:
                          </p>
                          <ul>
                              <li><b>BoolQ:</b> boolq.jsonl</li>
                              <li><b>MultiRC:</b> multirc.jsonl</li>
                              <li><b>E-SNLI:</b> esnli.jsonl</li>
                              <li><b>CoS-E:</b> cose.jsonl</li>
                              <li><b>Fever:</b> fever.jsonl</li>
                              <li><b>Evidence Inference:</b> evidence_inference.jsonl</li>
                              <li><b>Movies:</b> movies.jsonl</li>
                              <li><b>MetaData:</b> metadata.json</li>
                          </ul>
                          <p><span className="label label-rounded label-secondary">Note: </span> The metadata.json file should be of the format specified below.</p>
                          <pre className="code" data-lang="JSONL">
                            <code>
                              {this.state.metadata}
                            </code>
                          </pre>
                          <p>
                            <span className="label label-rounded label-secondary">Step 4</span>
                             Create a zip of the prediction JSONLs using <code>zip -r submission.zip *.jsonl</code>. The zip
                            can contain subfolders, but should not contain nested zips.
                          </p>
                          <p><span className="label label-rounded label-secondary">Step 5</span> Email the google group: <b>eraserbenchmark@googlegroups.com</b>
                          </p>

                      </div>
                  </details>

                  <details className="accordion">
                      <summary className="accordion-header">
                          <i className="icon icon-arrow-right mr-1"></i>
                          <b>Are there any rules or restrictions on submitted systems?</b>
                      </summary>
                      <div className="accordion-body">
                          <p>Submitted systems may use any public or private data when developing their systems, with a
                              few
                              exceptions:</p>
                          <p><span className="label label-rounded label-secondary">Exception 1</span> Systems may only use the
                              ERASER-distributed versions of the task datasets, as these
                              use
                              different train/validation/test splits from other public versions in some cases.</p>
                          <p><span className="label label-rounded label-secondary">Exception 2</span> Systems may not use the
                              unlabeled test data for the ERASER tasks in system development
                              in any way, and may not build systems that share information across separate test
                              examples in any way.</p>
                          <p><span className="label label-rounded label-secondary">Exception 3</span> Beyond this, you may
                              submit results from any kind of system that is capable of producing
                              labels for the six target tasks and the analysis task. This includes systems that do not
                              share any components across tasks or systems not based on machine learning.</p>
                      </div>
                  </details>

                  <QA value = {{
                    'Q': 'How do I add my result to the leaderboard?',
                    'A': 'An email containing a link to the submission document should be sent to the following google group: <b>eraserbenchmark@googlegroups.com</b>.'
                  }} />

                  <QA value = {{
                    'Q': 'What license is the ERASER data distributed under?',
                    'A': 'We defer to the licenses in the original datasets concerning the data; please see the respective links to these sources. For code that we distribute, consult our GitHub readme.'
                  }} />

                  <QA value = {{
                    'Q': 'How should I cite ERASER?',
                    'A': 'Download the citation text file <a href="/citations.txt"> here</a>, which contains citations for ERASER, as well as the other datasets.'
                  }} />

                  <QA value = {{
                    'Q': 'Are there any private test sets?',
                    'A': 'This is a work in progress.'
                  }} />

              </div>
          </div>
      </div>
    );
  }
}

export default FAQ;
