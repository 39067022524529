import React, { Component } from 'react';
import HeadBoard from './pages/headboard.js';
import Introduction from './pages/introduction.js';
import TaskBoard from './pages/taskboard.js';
import Leaderboard from './pages/leaderboard.js';
import Appendix from './pages/appendix.js';
import FAQ from './pages/faq.js';

class App extends Component {
  /**
  * A compilation of all sections together to form an 'app.'
  */
  
  render() {

    return (
      <div>
        <HeadBoard/>
        <Introduction/>
        <TaskBoard/>
        <Leaderboard/>
        <FAQ/>
        <Appendix/>
      </div>
    );
  }
}

export default App;
